import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import SEO from '../components/SEO'
import styled from 'styled-components'

const Component = ({ data: { markdownRemark: contact } }) => (
  <Layout>
    <SEO data={{ title: 'Kontakt' }} />
    <Content>
      <div dangerouslySetInnerHTML={{ __html: contact.html }} />
    </Content>
  </Layout>
)

export const ContactQuery = graphql`
  query contact {
    markdownRemark(frontmatter: { title: { eq: "contact" } }) {
      id
      html
    }
  }
`

export default Component

const Content = styled.div`
  text-align: center;
`
